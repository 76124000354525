<template>
  <base-section id="touchfree-spec">
    <v-container>
      <v-row>
        <v-col
          v-for="(news, i) in articles"
          :key="i"
          cols="12"
          md="12"
        >
          <news-card-touchfree
            v-bind="news"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionTouchfreeSpec',

    components: {
      NewsCardTouchfree: () => import('@/components/news/CardTouchfree'),
    },
    created () {
      this.articles[0].title = this.$t('touchfreespec.title1')
      this.articles[0].html = this.$t('touchfreespec.html1')
      this.articles[0].specs[0].name = this.$t('touchfreespec.specs.name1')
      this.articles[0].specs[0].desc = this.$t('touchfreespec.specs.desc1')
      this.articles[0].specs[1].name = this.$t('touchfreespec.specs.name2')
      this.articles[0].specs[1].desc = this.$t('touchfreespec.specs.desc2')
      this.articles[0].specs[2].name = this.$t('touchfreespec.specs.name3')
      this.articles[0].specs[2].desc = this.$t('touchfreespec.specs.desc3')
      this.articles[0].specs[3].name = this.$t('touchfreespec.specs.name4')
      this.articles[0].specs[3].desc = this.$t('touchfreespec.specs.desc4')
      this.articles[0].specs[4].name = this.$t('touchfreespec.specs.name5')
      this.articles[0].specs[4].desc = this.$t('touchfreespec.specs.desc5')
      this.articles[0].specs[5].name = this.$t('touchfreespec.specs.name6')
      this.articles[0].specs[5].desc = this.$t('touchfreespec.specs.desc6.first')
      this.articles[0].specs[5].descSec = this.$t('touchfreespec.specs.desc6.sec')
      this.articles[0].specs[6].name = this.$t('touchfreespec.specs.name7')
      this.articles[0].specs[6].desc = this.$t('touchfreespec.specs.desc7')
      this.articles[0].specs[7].name = this.$t('touchfreespec.specs.name8')
      this.articles[0].specs[7].desc = this.$t('touchfreespec.specs.desc8')

      this.$EventBus.$on('localeChanged', () => {
        this.articles[0].title = this.$t('touchfreespec.title1')
        this.articles[0].html = this.$t('touchfreespec.html1')
        this.articles[0].specs[0].name = this.$t('touchfreespec.specs.name1')
        this.articles[0].specs[0].desc = this.$t('touchfreespec.specs.desc1')
        this.articles[0].specs[1].name = this.$t('touchfreespec.specs.name2')
        this.articles[0].specs[1].desc = this.$t('touchfreespec.specs.desc2')
        this.articles[0].specs[2].name = this.$t('touchfreespec.specs.name3')
        this.articles[0].specs[2].desc = this.$t('touchfreespec.specs.desc3')
        this.articles[0].specs[3].name = this.$t('touchfreespec.specs.name4')
        this.articles[0].specs[3].desc = this.$t('touchfreespec.specs.desc4')
        this.articles[0].specs[4].name = this.$t('touchfreespec.specs.name5')
        this.articles[0].specs[4].desc = this.$t('touchfreespec.specs.desc5')
        this.articles[0].specs[5].name = this.$t('touchfreespec.specs.name6')
        this.articles[0].specs[5].desc = this.$t('touchfreespec.specs.desc6.first')
        this.articles[0].specs[5].descSec = this.$t('touchfreespec.specs.desc6.sec')
        this.articles[0].specs[6].name = this.$t('touchfreespec.specs.name7')
        this.articles[0].specs[6].desc = this.$t('touchfreespec.specs.desc7')
        this.articles[0].specs[7].name = this.$t('touchfreespec.specs.name8')
        this.articles[0].specs[7].desc = this.$t('touchfreespec.specs.desc8')
      })
    },
    data: () => ({
      articles: [
        {
          title: 'Examination Gloves',
          html: 'Nitrile, powder-free examination gloves for superior personal barrier protection<ul><li>Beaded cuff design and textured fingertips for confident, secure grip</li><li>Soft, durable nitrile material provides excellent protection and flexibility</li><li>Tested and comply according to ASTM D6319 and EN455 standards</li></ul>',
          readMore: true,
          src: require('@/assets/thumbnail.jpg'),
          link: 'https://touchfree.kr/product/%EA%B3%A0%ED%92%88%EC%A7%88-%ED%84%B0%EC%B9%98%ED%94%84%EB%A6%AC-%EB%8B%88%ED%8A%B8%EB%A6%B4-%EA%B8%80%EB%9F%AC%EB%B8%8C/11/category/1/display/2/',
          specs: [
            {
              name: 'TYPE',
              desc: 'NON-STERILE, POWDER FREE',
            },
            {
              name: 'COLORS',
              desc: 'REGULAR BLUE, BLACK, WHITE (SUPPORT OEM MANUFACTURING)',
            },
            {
              name: 'DESIGN FEATURES',
              desc: 'AMBIDEXTROUS, FINGER & PALM TEXTURED, BEADED CUFF',
            },
            {
              name: 'FINISHING',
              desc: 'ONLINE CHLORINATED',
            },
            {
              name: 'SIZE',
              desc: 'XS, S, M, L, XL, XXL',
            },
            {
              name: 'STANDARD PACKAGING',
              desc: '100 PCS X 10 INNER BOXES PER CARTON (XS TO L)',
              descSec: '90 PCS X 10 INNER BOXES PER CARTON (XL TO XXL)',
            },
            {
              name: 'LENGTH',
              desc: 'MIN. 240 MM',
            },
            {
              name: 'THICKNESS AT FINGER',
              desc: 'MIN. 0.09 MM',
            },
          ],
        },
      ],
    }),
  }
</script>
